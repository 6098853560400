<template>
  <section class="body">
    <section class="mod banner">
      <img class="img" src="../../assets/vbd/home/banner.png" width="1040" height="904" />
      <!-- <img class="img" src="../../assets/vbd/home/banner.jpg" width="1080" height="1183" /> -->
      <div class="summary">
        <h2><strong>亿点灵感</strong>矩阵智能车联网</h2>
        <ul class="tags">
          <li>自主研发</li>
          <li>软硬件全栈工具链</li>
          <li>ASIL-D最高安全等级</li>
        </ul>
      </div>
    </section>

    <section class="mod vcu">
      <div class="summary">
        <h3>智能整车控制决策单元<strong>VCU</strong></h3>
        <ul class="tags">
          <li>Autosar架构</li>
          <li>全功能接口</li>
          <li>ASIL-D最高安全等级</li>
        </ul>
        <router-link class="btn" to="/vcu">了解详情</router-link>
      </div>
      <img class="img" src="../../assets/vbd/home/vcu.png" width="1140" height="720" />
    </section>

    <section class="mod tbox">
      <div class="summary">
        <h3>智能网联车载单元<strong>T-BOX</strong></h3>
        <ul class="tags">
          <li>全芯国产</li>
          <li>Autosar架构</li>
          <li>通信安全</li>
        </ul>
        <router-link class="btn" to="/tbox">了解详情</router-link>
      </div>
      <img class="img" src="../../assets/vbd/home/tbox.png" width="1140" height="750" />
    </section>

    <section class="row">
      <router-link class="item yqKey" to="/ydkey">
        <img class="img" src="../../assets/vbd/home/ydkey.png" width="960" height="1000" />
        <h3>汽车智能数字钥匙</h3>
        <ul class="tags">
          <li>金融级钥匙安全模块</li>
          <li>多协议兼容</li>
          <li>全自动标定部署工具</li>
          <li>人工智能定位算法</li>
          <li>蓝牙loT设备接入</li>
        </ul>
      </router-link>
      <router-link class="item edr" to="/edr">
        <img class="img" src="../../assets/vbd/home/edr.png" width="960" height="1000" />
        <h3>汽车安全“黑匣子”<strong>EDR</strong></h3>
        <ul class="tags">
          <li>断电存储</li>
          <li>数据保存</li>
          <li>隔离保护</li>
          <li>数据安全</li>
          <li>数据采集</li>
        </ul>
      </router-link>
    </section>
  </section>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss" scoped>
.mod {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 850px;
  text-align: center;
  overflow: hidden;
  color: #fff;
  background-color: #434343;

  h2, h3 {
    font-size: 80px;
    color: #fff;
    font-weight: 300;
    line-height: 84px;

    strong {
      display: block;
      font-size: 120px;
      line-height: 156px;
    }
  }

  h3 {
    font-size: 60px;
    line-height: 64px;

    strong {
      padding-top: 16px;
      font-size: 220px;
      line-height: 248px;
      font-weight: 900;
      font-family: Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
    }
  }

  .tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .img {
    position: absolute;
  }
}

.banner {
  padding-top: 160px;
  background-color: #FF3F00;

  .summary {
    margin-left: 820px;
    white-space: nowrap;

    .tags {
      margin: 0 auto;
      padding: 28px 0 0;
      max-width: 400px;

      li {
        margin: 10px;
        padding: 0 18px;
        font-size: 22px;
        line-height: 38px;
        border: 2px solid #fff;
        border-radius: 6px;
      }
    }
  }

  .img {
    transform: translate(-330px, 55px);
  }
}


.vcu, .tbox {
  .summary {
    font-size: 24px;
    line-height: 28px;
    transform: translateX(-50%);

    .tags {
      li {
        padding: 0 32px;

        & + li {
          position: relative;

          &::before {
            position: absolute;
            left: 0;
            top: 3px;
            width: 1px;
            height: 22px;
            background-color: #fff;
            content: '';
          }
        }
      }
    }

    .btn {
      display: inline-block;
      margin-top: 52px;
      padding: 0 48px;
      vertical-align: top;
      font-size: 30px;
      line-height: 48px;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 48px;
      cursor: pointer;
    }
  }

  .img {
    transform: translate(350px, 65px);
  }
}

.tbox {
  background-color: #3B3C99;

  h3 {
    strong {
      font-size: 160px;
      line-height: 188px;
    }
  }

  .img {
    transform: translate(390px, 50px);
  }
}

.row {
  display: flex;

  .item {
    flex: 1;
    position: relative;
    display: flex;
    padding: 110px 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 1000px;
    font-size: 24px;
    line-height: 38px;
    color: #fff;
    overflow: hidden;
    background-color: #00A99D;

    h3 {
      position: relative;
      margin-bottom: 64px;
      font-size: 80px;
      line-height: 96px;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }

    .tags {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 780px;

      li {
        padding: 0 32px;

        & + li {
          position: relative;

          &::before {
            position: absolute;
            left: 0;
            top: 8px;
            width: 1px;
            height: 22px;
            background-color: #fff;
            content: '';
          }
        }
      }
    }

    .img {
      position: absolute;
      top: 0;
    }

    &.yqKey {
      .tags {
        li:nth-child(4) {
          &::before {
            display: none;
          }
        }
      }
    }


    &.edr {
      background-color: #F15A24;

      h3 {
        margin-bottom: 0;
        font-size: 48px;
        line-height: 58px;
        font-weight: normal;

        strong {
          display: block;
          font-size: 120px;
          line-height: 160px;
          text-align: center;
          font-weight: 900;
          font-family: Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
        }
      }

      .tags {
        max-width: 500px;

        li:nth-child(4) {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  .mod {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100vh;

    h2, h3 {
      font-size: 40px;
      line-height: 42px;

      strong {
        font-size: 60px;
        line-height: 76px;
      }
    }

    h3 {
      font-size: 30px;
      line-height: 32px;

      strong {
        padding-top: 8px;
        font-size: 110px;
        line-height: 124px;
      }
    }

    .img {
      position: static;
      transform: none;
      width: 100%;
      height: auto;
    }
  }

  .banner {
    padding-top: 72px;

    .summary {
      width: 100%;
      padding: 0 0 40px;
      margin-left: 0;
      margin-top: -60px;

      h2 {
        display: flex;
        flex-direction: column;
        align-items: center;

        strong {
          margin-bottom: 4px;
          // padding: 0 8px;
          // background-color: rgba($color: #000000, $alpha: 0.1);
          // border-radius: 6px;
        }
      }

      .tags {
        padding: 20px 0 0;
        max-width: 300px;

        li {
          margin: 4px;
          padding: 0 8px;
          font-size: 12px;
          line-height: 18px;
          border: 1px solid #fff;
          border-radius: 4px;
        }
      }
    }
  }


  .vcu, .tbox {
    padding: 40px 0;
    flex-direction: column-reverse;

    .summary {
      font-size: 12px;
      line-height: 16px;
      transform: none;

      .tags {
        li {
          padding: 0 12px;

          & + li {
            &::before {
              height: 10px;
            }
          }
        }
      }

      .btn {
        display: inline-block;
        margin-top: 24px;
        padding: 0 24px;
        font-size: 14px;
        line-height: 28px;
        border: 1px solid #fff;
        border-radius: 48px;
      }
    }
  }

  .tbox {
    background-color: #3B3C99;

    h3 {
      strong {
        font-size: 72px;
        line-height: 96px;
      }
    }
  }

  .row {
    display: block;

    .item {
      padding: 40px 0 80px;
      height: auto;
      font-size: 12px;
      line-height: 18px;
      min-height: 100vh;
      justify-content: space-evenly;

      h3 {
        margin-top: -120px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 48px;
      }

      .tags {
        max-width: 780px;

        li {
          padding: 0 12px;

          & + li {
            &::before {
              top: 4px;
              height: 10px;
            }
          }
        }
      }

      .img {
        position: static;
        top: 0;
        width: 100%;
        height: auto;
      }

      &.yqKey {
        .tags {
          max-width: 280px;

          li:nth-child(4) {
            &::before {
              display: block;
            }
          }
          li:nth-child(2n+1) {
            &::before {
              display: none;
            }
          }
        }
      }


      &.edr {
        h3 {
          font-size: 24px;
          line-height: 30px;

          strong {
            font-size: 60px;
            line-height: 80px;
          }
        }

        .tags {
          max-width: 240px;

          li:nth-child(4) {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

}
</style>